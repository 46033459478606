var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._l(_vm.items, function (item, i) {
    return _c('div', {
      key: i
    }, [_c('div', [_c('h4', {
      staticClass: "grey--text text--darken-4 minw100"
    }, [_c('v-tooltip', {
      attrs: {
        "top": "",
        "open-delay": 10,
        "close-delay": 5
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref) {
          var on = _ref.on,
            attrs = _ref.attrs;
          return [_c('v-icon', _vm._g(_vm._b({
            attrs: {
              "color": "mango-red",
              "small": ""
            },
            on: {
              "click": function ($event) {
                return _vm.copyToClipboard(item);
              }
            }
          }, 'v-icon', attrs, false), on), [_vm._v(" mdi-content-copy ")])];
        }
      }], null, true)
    }, [_c('span', [_vm._v("Copy")])]), _c('span', {
      staticClass: "ml-1 font-weight-bold"
    }, [_vm._v(_vm._s(item))])], 1)]), i + 1 !== _vm.items.length ? _c('v-divider', {
      key: i
    }) : _vm._e()], 1);
  }), 0);

}
var staticRenderFns = []

export { render, staticRenderFns }