<template>
  <div>
    <div class="mb-7">
      <v-toolbar
        fixed
        :class="
          'text font-weight-bold ' +
          colorize(statusList.find((item) => item.id === status).id + 1)
        "
        dense
        dark
        flat
        rounded
      >
        {{ statusList.find((item) => item.id === status).label }}
        <v-spacer></v-spacer>

        <v-btn
          v-if="selected.length > 0"
          color="white lighten-3 primary--text"
          light
        >
          <v-icon small class="mr-2">mdi-calculator</v-icon>
          <span>{{ this.getSumOfSelectedInvoices() }}</span>
        </v-btn>

        <v-btn
          color="white lighten-3 third--text"
          class="ml-2"
          v-if="
            selected.length > 0 && (status !== 0 ? checkSameFortnox() : true)
          "
          light
          @click="
            status !== 0
              ? (selectedItems.fortnoxNumber = selected[0].fortnoxNumber)
              : (selectedItems.fortnoxNumber = null);
            editSelected = true;
          "
        >
          <v-icon small class="mr-2">mdi-pencil</v-icon>
          Update
        </v-btn>

        <v-btn
          color="white lighten-3 greeny--text"
          class="ml-2"
          v-if="
            selected.length > 0 &&
            status !== 3 &&
            checkSameFortnox() &&
            checkSameCompany()
          "
          light
          @click="markSelected = true"
        >
          <v-icon small class="mr-2">mdi-check</v-icon>
          Mark As {{ statusList.find((item) => item.id === status + 1).label }}
        </v-btn>
      </v-toolbar>
      <v-data-table
        v-model="selected"
        :headers="
          getHeader(statusList.find((item) => item.id === status).label)
        "
        :items="invoices"
        :loading="loading"
        show-select
        fixed-header
        :height="invoices.length > 9 ? 500 : null"
        class="elevation-1"
        :footer-props="{
          'items-per-page-options': [10, 25, 50, 100, 150, 200],
        }"
        :options.sync="tableOptions"
        :server-items-length="invoicesTotalLength"
        @update:items-per-page="getInvoiceList"
        @update:page="getInvoiceList"
        @update:sort-by="getInvoiceList"
        @update:sort-desc="getInvoiceList"
      >
        <template v-slot:[`item.orderId`]="{ item }">
          <OrderIDField :order="item.order" style="width: 140px"></OrderIDField>
          <!-- <OrderIconsField
              :order="item.order"
              :showPrintableInfo="true"
              :showDistributedInfo="true"
              :showGroupOrderInfo="true"
              :showClaimInfo="true"
              :showArchiveOrder="true"
            ></OrderIconsField> -->
        </template>
        <template v-slot:[`item.company.name`]="{ item }">
          <div class="d-flex flex-column">
            <div>
              <v-tooltip color="black" bottom style="cursor: pointer">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="accent"
                    class="mr-1"
                    @click="
                      companyDetail = item.company;
                      initCustomersByCompanyId(item.company.id);
                      detailDialog = true;
                    "
                    small
                  >
                    mdi-eye
                  </v-icon>
                  <span
                    v-bind="attrs"
                    v-on="on"
                    class="threeDots font-weight-bold"
                    style="max-width: 180px; margin-bottom: -6px"
                  >
                    {{ item.company.name }}
                  </span>
                </template>
                <div v-if="item.company.name" style="max-width: 200px">
                  <b>{{ item.company.name }}</b>
                </div>
              </v-tooltip>
            </div>
          </div>
        </template>
        <template v-slot:[`item.customer.firstName`]="{ item }">
          <div v-if="item.customer">
            <b>{{ item.customer.firstName }} {{ item.customer.lastName }}</b>
            <div class="grey--text text--darken-1">
              {{ item.customer.email }}
            </div>
          </div>
          <div v-else>
            <b
            >{{ item.order.customer.firstName }}
              {{ item.order.customer.lastName }}</b
            >
            <div class="grey--text text--darken-1">
              {{ item.order.customer.email }}
            </div>
          </div>
        </template>

        <template v-slot:[`item.bookings`]="{ item }">
          <BookingIdField
            v-if="item.order.bookings.length > 0"
            :items="convertBookingsToBookingNumbers(item.order.bookings)"
          ></BookingIdField>
          <span v-else>N/A</span>
        </template>

        <template v-slot:[`item.fortnoxNumber`]="{ item }">
          <div v-if="item.fortnoxNumber">
            <v-icon
              color="primary"
              @click="copyToClipboard(item.fortnoxNumber)"
              small
            >
              mdi-content-copy
            </v-icon>
            <span class="ml-1 font-weight-bold">{{ item.fortnoxNumber }}</span>
          </div>
          <span v-else>N/A</span>
        </template>

        <template
          v-slot:[`item.order.calculatedPrice.total.plainText`]="{ item }"
        >
          <b>{{ item.order.calculatedPrice.total.plainText }}</b>
        </template>
        <template v-slot:[`item.created`]="{ item }">
          <div class="grey--text text--darken-4 font-weight-medium one-line">
            {{ item.created | localizeUTCAndGetDate }}
            <span class="grey--text text--darken-4 font-weight-regular">
              <v-icon size="18" class="third--text clock"
              >mdi-clock-time-eight-outline</v-icon
              >
              {{ item.created | localizeUTCAndGetTime }}</span
            >
          </div>
          <div v-if="item.debitedBy" class="grey--text text--darken-1 one-line">
            by {{ item.debitedBy.fullName ?? item.debitedBy.name }}
          </div>
        </template>

        <template v-slot:[`item.mustBePaidDate`]="{ item }">
          <div
            class="grey--text text--darken-4 font-weight-medium one-line"
            v-if="item.invoicedInExternalSystem"
          >
            {{
              item.invoicedInExternalSystem
                | localizeUTCAddDaysAndGetDate(item.company.allowedDaysToPay)
            }}
          </div>
          <span v-else>N/A</span>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            icon
            color=" accent"
            :disabled="item.events.length === 0"
            light
            @click="eventDetail = item"
          >
            <v-icon>mdi-history</v-icon>
          </v-btn>
          <v-btn
            icon
            color="third"
            light
            @click="
              editItem = item;
              tempFortnoxNumber = item.fortnoxNumber;
              tempComment = item.comment;
            "
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            v-if="item.status !== 3"
            icon
            color="greeny"
            light
            @click="
              markAsItem = item;
              tempFortnoxNumber = item.fortnoxNumber;
              tempComment = item.comment;
            "
          >
            <v-tooltip color="black" bottom style="cursor: pointer">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">mdi-check</v-icon>
              </template>
              <div>
                <span
                >Mark as
                  {{
                    statusList.find((itemx) => itemx.id === item.status + 1)
                      .label
                  }}</span
                >
              </div>
            </v-tooltip>
          </v-btn>
        </template>
      </v-data-table>
    </div>

    <v-dialog v-if="editSelected" max-width="700px" v-model="editSelected">
      <v-form ref="editSelectedForm">
        <v-card>
          <v-toolbar fixed color="titlebg" class="title" dark
          >Update Selected Invoices
          </v-toolbar
          >
          <v-card-text>
            <v-container class="mt-5">
              <v-text-field
                v-if="status !== 0"
                v-model="selectedItems.fortnoxNumber"
                label="Invoice Number"
                hide-details="auto"
                :rules="validate.input"
                outlined
                required
                class="mt-5"
              ></v-text-field>
              <v-textarea
                class="mt-4"
                outlined
                label="Comment"
                hide-details
                clearable
                v-model="selectedItems.comment"
              />
            </v-container>
          </v-card-text>
          <v-card-actions class="pb-8 pr-8 pt-0">
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @click="
                clearSelectedItems();
                editSelected = false;
              "
            >
              Cancel
            </v-btn
            >
            <v-btn
              :loading="loading"
              color="accent"
              @click="
                updateSelectedInvoices(status);
                editSelected = false;
              "
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog
      v-if="editItem"
      max-width="700px"
      v-model="editItem"
      @click:outside="closeEditItem()"
    >
      <v-form ref="editItemForm">
        <v-card>
          <v-toolbar fixed color="titlebg" class="title" dark>
            <v-toolbar-title class="ml-2">Update Invoice</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="closeEditItem()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-container>
              {{ tempFortnox }}
              <v-text-field
                v-if="editItem.status !== 0"
                v-model="editItem.fortnoxNumber"
                label="Invoice Number"
                hide-details="auto"
                :rules="validate.input"
                outlined
                required
                class="mt-5"
              ></v-text-field>
              <v-textarea
                class="mt-4"
                outlined
                label="Comment"
                hide-details
                clearable
                v-model="editItem.comment"
              />
            </v-container>
          </v-card-text>

          <v-card-actions class="pb-8 pr-8 pt-0">
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="closeEditItem()"> Cancel</v-btn>
            <v-btn :loading="loading" color="accent" @click="updateInvoice">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog
      v-if="markAsItem"
      max-width="700px"
      v-model="markAsItem"
      @click:outside="closeMarkAsItem()"
    >
      <v-form ref="markAsItemForm">
        <v-card>
          <v-toolbar fixed color="titlebg" class="title" dark>
            <v-toolbar-title
            >Mark as
              {{
                statusList.find(
                  (item) =>
                    item.id ===
                    (markAsItem.status !== 3
                      ? markAsItem.status + 1
                      : markAsItem.status)
                ).label
              }}
            </v-toolbar-title
            >
            <v-spacer></v-spacer>
            <v-btn icon @click="closeMarkAsItem()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar
          >
          <v-card-text>
            <v-container>
              <v-text-field
                v-model="markAsItem.fortnoxNumber"
                v-if="markAsItem.status === 0"
                label="Invoice Number"
                hide-details="auto"
                :rules="validate.input"
                outlined
                required
                class="mt-5"
              ></v-text-field>
              <v-textarea
                class="mt-4"
                outlined
                label="Comment"
                hide-details
                clearable
                v-model="markAsItem.comment"
              />
            </v-container>
          </v-card-text>
          <v-card-actions class="pb-8 pr-8 pt-0">
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="closeMarkAsItem()"> Cancel</v-btn>
            <v-btn
              :loading="loading"
              color="accent"
              @click="markAs(markAsItem.status + 1)"
            >
              Mark as
              {{
                statusList.find((item) => item.id === markAsItem.status + 1)
                  .label
              }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-if="markSelected" max-width="700px" v-model="markSelected">
      <v-form ref="markAsItemSelectedForm">
        <v-card>
          <v-toolbar fixed color="titlebg" class="title" dark
          >Mark as
            {{
              statusList.find(
                (item) => item.id === (status !== 3 ? status + 1 : status)
              ).label
            }}
          </v-toolbar
          >
          <v-card-text>
            <v-container>
              <v-text-field
                v-model="selectedItems.fortnoxNumber"
                v-if="status === 0"
                label="Invoice Number"
                hide-details="auto"
                :rules="validate.input"
                outlined
                required
                class="mt-5"
              ></v-text-field>
              <v-textarea
                class="mt-4"
                outlined
                label="Comment"
                hide-details
                clearable
                v-model="selectedItems.comment"
              />
            </v-container>
          </v-card-text>
          <v-card-actions class="pb-8 pr-8 pt-0">
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="markSelected = null"> Cancel</v-btn>
            <v-btn
              :loading="loading"
              color="accent"
              @click="
                markAsSelectedItems(status);

                markSelected = false;
              "
            >
              Mark as
              {{ statusList.find((item) => item.id === status + 1).label }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog
      v-if="eventDetail"
      v-model="eventDetail"
      @click:outside="eventDetail = false"
      max-width="700px"
    >
      <v-card>
        <v-toolbar fixed color="titlebg" class="title" dense dark>
          <v-toolbar-title>Invoice Events History</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="eventDetail = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <div class="px-4 py-4">
          <v-row>
            <v-col>
              <v-card-text
                style="max-height: 500px; overflow: auto"
                class="py-0 elevation-2 rounded-lg"
              >
                <v-timeline align-top dense>
                  <v-timeline-item
                    v-for="(event, index) in eventDetail.events"
                    :key="index"
                    small
                  >
                    <v-row class="pt-1">
                      <v-col cols="3">
                        <strong>{{ event.datetime }}</strong>
                      </v-col>
                      <v-col>
                        <strong>{{ event.user.fullName ?? event.user.name }}</strong>
                        <div class="text-caption">
                          {{ event.description }}
                        </div>
                      </v-col>
                    </v-row>
                  </v-timeline-item>
                </v-timeline>
              </v-card-text>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="detailDialog"
      :loading="isLoading"
      width="1000px"
      class="pa-5"
      @click:outside="detailDialog = false"
    >
      <v-card>
        <v-toolbar fixed color="titlebg" class="title" dense dark>
          <v-toolbar-title class="ml-2"
          >Company :
            {{ companyDetail && companyDetail.name }}
          </v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn icon @click="detailDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <companyDetail
          v-if="companyDetail"
          :company="companyDetail"
          :loading="isLoading"
        />

      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Trainplanet from "@/util/trainplanet.api";
import common from "@/mixins/common";
import BookingIdField from "@/components/common/BookingIdField";
import OrderIconsField from "@/components/common/OrderIconsField";
import {copyToClipboard, debounce, validator} from "@/util/helpers";
import OrderIDField from "@/components/common/OrderIDField";
import copyToClipboardField from "@/components/common/copyToClipboardField";
import companyDetail from "@/components/info/companyDetail";

export default {
  name: "InvoiceTable",
  components: {
    OrderIDField,
    OrderIconsField,
    BookingIdField,
    copyToClipboardField,
    companyDetail,
  },
  mixins: [common],
  props: [
    "selectedTenants",
    "status",
    "tenants",
    "updatedStatus",
    "justMount",
    "companyId",
    "fortnoxNumber",
    "headers",
  ],
  data: () => ({
    tableOptions: {},
    invoicesTotalLength: -1,
    validate: {
      greater: validator.greaterZeroCheck(),
      input: validator.required(),
      phone: validator.phone(),
      postalcode: validator.postalCode(),
      email: validator.email(),
      orderNumber: validator.orderNumber(),
      select: validator.select(),
    },
    isLoading: false,
    companyDetail: null,
    detailDialog: false,
    tempFortnoxNumber: null,
    tempComment: null,
    editSelected: false,
    markSelected: false,
    sameFortnox: false,
    selected: [],
    loading: false,
    invoices: [],
    saveLoader: false,
    editItem: null,
    markAsItem: null,
    eventDetail: false,
    selectedItems: {
      status: null,
      fortnoxNumber: null,
      comment: null,
    },
    statusList: [
      {
        label: "Pending",
        id: 0,
      },
      {
        label: "Invoiced",
        id: 1,
      },
      {
        label: "Paid",
        id: 2,
      },
      {
        label: "Accounted",
        id: 3,
      },
    ],
  }),
  mounted() {
    if (this.justMount) {
      this.getInvoiceList();
    }
  },
  watch: {
    selectedTenants: function () {
      if (this.tenants.length > 0) {
        this.getInvoiceList();
      }
    },
    updatedStatus: function () {
      if (this.updatedStatus === this.status) {
        this.getInvoiceList();
      }
    },

    companyId: {
      deep: true,
      handler: function (value) {
        this.getInvoiceList();
      },
    },
    fortnoxNumber: function () {
      const func = this.getInvoiceList;  // make the function reachable inside the debounce
      return debounce(function () {
        func();
      }, 500,
        this.status, // send the status as parameter, so it works with together with InvoiceTables
        true)
    }
  },
  methods: {
    copyToClipboard,
    getHeader(status) {
      return this.headers;
      // switch (status) {
      //   case "Pending":
      //     return this.headers.filter((x) => x.value != "mustBePaidDate");
      //   case "Invoiced":
      //     return this.headers
      //   case "Paid":
      //     return this.headers.filter((x) => x.value != "mustBePaidDate");
      //   case "Accounted":
      //     return this.headers.filter((x) => x.value != "mustBePaidDate");
      //   default:
      //     return this.headers;
      // }
    },
    colorize(x) {
      switch (x) {
        case 1:
          return `primary`;
        case 2:
          return `third`;
        case 3:
          return `greeny`;
        case 4:
          return `accent`;
        default:
          return `accent`;
      }
    },

    closeEditItem() {
      this.editItem.fortnoxNumber = this.tempFortnoxNumber;
      this.editItem.comment = this.tempComment;
      this.editItem = null;
    },

    closeMarkAsItem() {
      this.markAsItem.fortnoxNumber = this.tempFortnoxNumber;
      this.markAsItem.comment = this.tempComment;
      this.markAsItem = null;
    },
    async getInvoiceList() {
      try {
        this.loading = true;

        const res = await Trainplanet.getInvoiceList({
          tenantIds: this.selectedTenants,
          fortnoxNumber: this.fortnoxNumber ? this.fortnoxNumber : null,
          companyId: this.companyId ? this.companyId : null,

          status: this.status,
          limit: this.tableOptions.itemsPerPage,
          offset: (this.tableOptions.page - 1) * this.tableOptions.itemsPerPage,
          sortBy: this.tableOptions.sortBy ? this.tableOptions.sortBy[0] : null,
          sortDescending: this.tableOptions.sortDesc ? this.tableOptions.sortDesc[0] : false,
        });

        this.invoices = res.invoices;
        this.invoicesTotalLength = res.meta.total;
      } catch (error) {
        await this.$store.dispatch("error", error.response.data.message);
      } finally {
        this.loading = false;
        await this.$store.dispatch("loading", false);
      }
    },
    checkSameFortnox() {
      const selectedFortnoxNumber = [
        ...new Set(this.selected.map((x) => x.fortnoxNumber)),
      ];
      return selectedFortnoxNumber.length === 1;
    },
    getSumOfSelectedInvoices() {
      let currency = null;
      let totalAmount = 0;

      for (const invoice of this.selected) {
        const price = invoice.order.calculatedPrice.total;

        if (currency === null) {
          currency = price.currency;
        } else if (currency !== price.currency) {
          return 'Selected invoices have mixed currencies.';
        }

        totalAmount += price.amount;
      }

      return Intl.NumberFormat('sv-SE', {
        style: 'currency',
        currency: currency
      }).format(totalAmount);
    },
    checkSameCompany() {
      const selectedCompany = [
        ...new Set(this.selected.map((x) => x.company.id)),
      ];
      return selectedCompany.length === 1;
    },
    async updateInvoice() {
      if (this.$refs[`editItemForm`].validate()) {
        try {
          await this.$store.dispatch("loading", true);
          const invoiceId = this.editItem.id;
          const body = {
            customer: this.editItem.customer
              ? this.editItem.customer
              : this.editItem.order.customer,
            company: this.editItem.company,
            comment: this.editItem.comment,
            status: this.editItem.status,
            reference: this.editItem.reference,
            fortnoxNumber: this.editItem.fortnoxNumber,
          };
          await Trainplanet.updateInvoice(invoiceId, body);
        } catch (error) {
          await this.$store.dispatch("error", error.response.data.message);
        } finally {
          await this.getInvoiceList();
          await this.$store.dispatch("loading", false);
          this.$emit("onUpdate", this.editItem.status);
          this.editItem = null;
        }
      }
    },

    async markAs(statusId) {
      if (this.$refs[`markAsItemForm`].validate()) {
        try {
          await this.$store.dispatch("loading", true);
          const invoiceId = this.markAsItem.id;
          const body = {
            customer: this.markAsItem.customer
              ? this.markAsItem.customer
              : this.markAsItem.order.customer,
            company: this.markAsItem.company,
            comment: this.markAsItem.comment,
            status: statusId,
            reference: this.markAsItem.reference,
            fortnoxNumber: this.markAsItem.fortnoxNumber,
          };
          await Trainplanet.updateInvoice(invoiceId, body);
          await this.getInvoiceList();
          this.$emit("onUpdate", body.status);
          this.markAsItem = null;
        } catch (error) {
          await this.$store.dispatch("error", error.response.data.message);
        } finally {
          await this.$store.dispatch("loading", false);
        }
      }
    },

    async initCustomersByCompanyId(companyId) {
      try {
        this.isLoading = true;
        const res = await Trainplanet.getCustomerList({companyId});
        this.companyDetail.customers = res.customers;
      } catch (error) {
        await this.$store.dispatch(
          "error",
          error.response.data.message || error.message
        );
      } finally {
        this.isLoading = false;
      }
    },
    async markAsSelectedItems(statusId) {
      if (this.$refs[`markAsItemSelectedForm`].validate()) {
        await this.$store.dispatch("loading", true);
        for (let i = 0; i < this.selected.length; i++) {
          try {
            const invoiceId = this.selected[i].id;
            const body = {
              customer: this.selected[i].customer
                ? this.selected[i].customer
                : this.selected[i].order.customer,
              company: this.selected[i].company,
              comment: this.selectedItems.comment,
              status: statusId + 1,
              reference: this.selected[i].reference,
              fortnoxNumber:
                statusId !== 0
                  ? this.selected[i].fortnoxNumber
                  : this.selectedItems.fortnoxNumber,
            };
            await Trainplanet.updateInvoice(invoiceId, body);
          } catch (error) {
            await this.$store.dispatch("error", error.response.data.message);
          } finally {
          }
        }

        await this.getInvoiceList();
        await this.$store.dispatch("loading", false);
        this.$emit("onUpdate", statusId + 1);
        this.clearSelectedItems();
      }
    },

    clearSelectedItems() {
      this.selectedItems.comment = null;
      this.selectedItems.status = null;
      this.selectedItems.fortnoxNumber = null;
      this.selected = []
    },

    async updateSelectedInvoices(status) {
      if (this.$refs[`editSelectedForm`].validate()) {
        await this.$store.dispatch("loading", true);
        for (let i = 0; i < this.selected.length; i++) {
          try {
            const invoiceId = this.selected[i].id;
            const body = {
              customer: this.selected[i].customer
                ? this.selected[i].customer
                : this.selected[i].order.customer,
              company: this.selected[i].company,
              comment: this.selectedItems.comment,
              status: this.selected[i].status,
              reference: this.selected[i].reference,
              fortnoxNumber:
                status !== 0
                  ? this.selectedItems.fortnoxNumber
                  : this.selected[i].fortnoxNumber,
            };
            await Trainplanet.updateInvoice(invoiceId, body);
          } catch (error) {
            await this.$store.dispatch("error", error.response.data.message);
          } finally {
          }
        }
        await this.getInvoiceList();
        await this.$store.dispatch("loading", false);
        this.$emit("onUpdate", status);
        this.clearSelectedItems();
        this.selected = [];
      }
    },

    convertBookingsToBookingNumbers(bookings) {
      return bookings?.map((booking) => booking.externalOrderId);
    },
  },
};
</script>

<style lang="scss" scoped></style>
