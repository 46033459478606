var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "mb-7"
  }, [_c('v-toolbar', {
    class: 'text font-weight-bold ' + _vm.colorize(_vm.statusList.find(function (item) {
      return item.id === _vm.status;
    }).id + 1),
    attrs: {
      "fixed": "",
      "dense": "",
      "dark": "",
      "flat": "",
      "rounded": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.statusList.find(function (item) {
    return item.id === _vm.status;
  }).label) + " "), _c('v-spacer'), _vm.selected.length > 0 ? _c('v-btn', {
    attrs: {
      "color": "white lighten-3 primary--text",
      "light": ""
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-calculator")]), _c('span', [_vm._v(_vm._s(this.getSumOfSelectedInvoices()))])], 1) : _vm._e(), _vm.selected.length > 0 && (_vm.status !== 0 ? _vm.checkSameFortnox() : true) ? _c('v-btn', {
    staticClass: "ml-2",
    attrs: {
      "color": "white lighten-3 third--text",
      "light": ""
    },
    on: {
      "click": function ($event) {
        _vm.status !== 0 ? _vm.selectedItems.fortnoxNumber = _vm.selected[0].fortnoxNumber : _vm.selectedItems.fortnoxNumber = null;
        _vm.editSelected = true;
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-pencil")]), _vm._v(" Update ")], 1) : _vm._e(), _vm.selected.length > 0 && _vm.status !== 3 && _vm.checkSameFortnox() && _vm.checkSameCompany() ? _c('v-btn', {
    staticClass: "ml-2",
    attrs: {
      "color": "white lighten-3 greeny--text",
      "light": ""
    },
    on: {
      "click": function ($event) {
        _vm.markSelected = true;
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-check")]), _vm._v(" Mark As " + _vm._s(_vm.statusList.find(function (item) {
    return item.id === _vm.status + 1;
  }).label) + " ")], 1) : _vm._e()], 1), _c('v-data-table', {
    staticClass: "elevation-1",
    attrs: {
      "headers": _vm.getHeader(_vm.statusList.find(function (item) {
        return item.id === _vm.status;
      }).label),
      "items": _vm.invoices,
      "loading": _vm.loading,
      "show-select": "",
      "fixed-header": "",
      "height": _vm.invoices.length > 9 ? 500 : null,
      "footer-props": {
        'items-per-page-options': [10, 25, 50, 100, 150, 200]
      },
      "options": _vm.tableOptions,
      "server-items-length": _vm.invoicesTotalLength
    },
    on: {
      "update:options": function ($event) {
        _vm.tableOptions = $event;
      },
      "update:items-per-page": _vm.getInvoiceList,
      "update:page": _vm.getInvoiceList,
      "update:sort-by": _vm.getInvoiceList,
      "update:sort-desc": _vm.getInvoiceList
    },
    scopedSlots: _vm._u([{
      key: `item.orderId`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('OrderIDField', {
          staticStyle: {
            "width": "140px"
          },
          attrs: {
            "order": item.order
          }
        })];
      }
    }, {
      key: `item.company.name`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('div', {
          staticClass: "d-flex flex-column"
        }, [_c('div', [_c('v-tooltip', {
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "color": "black",
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref3) {
              var on = _ref3.on,
                attrs = _ref3.attrs;
              return [_c('v-icon', {
                staticClass: "mr-1",
                attrs: {
                  "color": "accent",
                  "small": ""
                },
                on: {
                  "click": function ($event) {
                    _vm.companyDetail = item.company;
                    _vm.initCustomersByCompanyId(item.company.id);
                    _vm.detailDialog = true;
                  }
                }
              }, [_vm._v(" mdi-eye ")]), _c('span', _vm._g(_vm._b({
                staticClass: "threeDots font-weight-bold",
                staticStyle: {
                  "max-width": "180px",
                  "margin-bottom": "-6px"
                }
              }, 'span', attrs, false), on), [_vm._v(" " + _vm._s(item.company.name) + " ")])];
            }
          }], null, true)
        }, [item.company.name ? _c('div', {
          staticStyle: {
            "max-width": "200px"
          }
        }, [_c('b', [_vm._v(_vm._s(item.company.name))])]) : _vm._e()])], 1)])];
      }
    }, {
      key: `item.customer.firstName`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [item.customer ? _c('div', [_c('b', [_vm._v(_vm._s(item.customer.firstName) + " " + _vm._s(item.customer.lastName))]), _c('div', {
          staticClass: "grey--text text--darken-1"
        }, [_vm._v(" " + _vm._s(item.customer.email) + " ")])]) : _c('div', [_c('b', [_vm._v(_vm._s(item.order.customer.firstName) + " " + _vm._s(item.order.customer.lastName))]), _c('div', {
          staticClass: "grey--text text--darken-1"
        }, [_vm._v(" " + _vm._s(item.order.customer.email) + " ")])])];
      }
    }, {
      key: `item.bookings`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return [item.order.bookings.length > 0 ? _c('BookingIdField', {
          attrs: {
            "items": _vm.convertBookingsToBookingNumbers(item.order.bookings)
          }
        }) : _c('span', [_vm._v("N/A")])];
      }
    }, {
      key: `item.fortnoxNumber`,
      fn: function (_ref6) {
        var item = _ref6.item;
        return [item.fortnoxNumber ? _c('div', [_c('v-icon', {
          attrs: {
            "color": "primary",
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.copyToClipboard(item.fortnoxNumber);
            }
          }
        }, [_vm._v(" mdi-content-copy ")]), _c('span', {
          staticClass: "ml-1 font-weight-bold"
        }, [_vm._v(_vm._s(item.fortnoxNumber))])], 1) : _c('span', [_vm._v("N/A")])];
      }
    }, {
      key: `item.order.calculatedPrice.total.plainText`,
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_c('b', [_vm._v(_vm._s(item.order.calculatedPrice.total.plainText))])];
      }
    }, {
      key: `item.created`,
      fn: function (_ref8) {
        var _item$debitedBy$fullN;
        var item = _ref8.item;
        return [_c('div', {
          staticClass: "grey--text text--darken-4 font-weight-medium one-line"
        }, [_vm._v(" " + _vm._s(_vm._f("localizeUTCAndGetDate")(item.created)) + " "), _c('span', {
          staticClass: "grey--text text--darken-4 font-weight-regular"
        }, [_c('v-icon', {
          staticClass: "third--text clock",
          attrs: {
            "size": "18"
          }
        }, [_vm._v("mdi-clock-time-eight-outline")]), _vm._v(" " + _vm._s(_vm._f("localizeUTCAndGetTime")(item.created)))], 1)]), item.debitedBy ? _c('div', {
          staticClass: "grey--text text--darken-1 one-line"
        }, [_vm._v(" by " + _vm._s((_item$debitedBy$fullN = item.debitedBy.fullName) !== null && _item$debitedBy$fullN !== void 0 ? _item$debitedBy$fullN : item.debitedBy.name) + " ")]) : _vm._e()];
      }
    }, {
      key: `item.mustBePaidDate`,
      fn: function (_ref9) {
        var item = _ref9.item;
        return [item.invoicedInExternalSystem ? _c('div', {
          staticClass: "grey--text text--darken-4 font-weight-medium one-line"
        }, [_vm._v(" " + _vm._s(_vm._f("localizeUTCAddDaysAndGetDate")(item.invoicedInExternalSystem, item.company.allowedDaysToPay)) + " ")]) : _c('span', [_vm._v("N/A")])];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref10) {
        var item = _ref10.item;
        return [_c('v-btn', {
          attrs: {
            "icon": "",
            "color": " accent",
            "disabled": item.events.length === 0,
            "light": ""
          },
          on: {
            "click": function ($event) {
              _vm.eventDetail = item;
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-history")])], 1), _c('v-btn', {
          attrs: {
            "icon": "",
            "color": "third",
            "light": ""
          },
          on: {
            "click": function ($event) {
              _vm.editItem = item;
              _vm.tempFortnoxNumber = item.fortnoxNumber;
              _vm.tempComment = item.comment;
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-pencil")])], 1), item.status !== 3 ? _c('v-btn', {
          attrs: {
            "icon": "",
            "color": "greeny",
            "light": ""
          },
          on: {
            "click": function ($event) {
              _vm.markAsItem = item;
              _vm.tempFortnoxNumber = item.fortnoxNumber;
              _vm.tempComment = item.comment;
            }
          }
        }, [_c('v-tooltip', {
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "color": "black",
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref11) {
              var on = _ref11.on,
                attrs = _ref11.attrs;
              return [_c('v-icon', _vm._g(_vm._b({}, 'v-icon', attrs, false), on), [_vm._v("mdi-check")])];
            }
          }], null, true)
        }, [_c('div', [_c('span', [_vm._v("Mark as " + _vm._s(_vm.statusList.find(function (itemx) {
          return itemx.id === item.status + 1;
        }).label))])])])], 1) : _vm._e()];
      }
    }], null, true),
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  })], 1), _vm.editSelected ? _c('v-dialog', {
    attrs: {
      "max-width": "700px"
    },
    model: {
      value: _vm.editSelected,
      callback: function ($$v) {
        _vm.editSelected = $$v;
      },
      expression: "editSelected"
    }
  }, [_c('v-form', {
    ref: "editSelectedForm"
  }, [_c('v-card', [_c('v-toolbar', {
    staticClass: "title",
    attrs: {
      "fixed": "",
      "color": "titlebg",
      "dark": ""
    }
  }, [_vm._v("Update Selected Invoices ")]), _c('v-card-text', [_c('v-container', {
    staticClass: "mt-5"
  }, [_vm.status !== 0 ? _c('v-text-field', {
    staticClass: "mt-5",
    attrs: {
      "label": "Invoice Number",
      "hide-details": "auto",
      "rules": _vm.validate.input,
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.selectedItems.fortnoxNumber,
      callback: function ($$v) {
        _vm.$set(_vm.selectedItems, "fortnoxNumber", $$v);
      },
      expression: "selectedItems.fortnoxNumber"
    }
  }) : _vm._e(), _c('v-textarea', {
    staticClass: "mt-4",
    attrs: {
      "outlined": "",
      "label": "Comment",
      "hide-details": "",
      "clearable": ""
    },
    model: {
      value: _vm.selectedItems.comment,
      callback: function ($$v) {
        _vm.$set(_vm.selectedItems, "comment", $$v);
      },
      expression: "selectedItems.comment"
    }
  })], 1)], 1), _c('v-card-actions', {
    staticClass: "pb-8 pr-8 pt-0"
  }, [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.clearSelectedItems();
        _vm.editSelected = false;
      }
    }
  }, [_vm._v(" Cancel ")]), _c('v-btn', {
    attrs: {
      "loading": _vm.loading,
      "color": "accent"
    },
    on: {
      "click": function ($event) {
        _vm.updateSelectedInvoices(_vm.status);
        _vm.editSelected = false;
      }
    }
  }, [_vm._v(" Save ")])], 1)], 1)], 1)], 1) : _vm._e(), _vm.editItem ? _c('v-dialog', {
    attrs: {
      "max-width": "700px"
    },
    on: {
      "click:outside": function ($event) {
        return _vm.closeEditItem();
      }
    },
    model: {
      value: _vm.editItem,
      callback: function ($$v) {
        _vm.editItem = $$v;
      },
      expression: "editItem"
    }
  }, [_c('v-form', {
    ref: "editItemForm"
  }, [_c('v-card', [_c('v-toolbar', {
    staticClass: "title",
    attrs: {
      "fixed": "",
      "color": "titlebg",
      "dark": ""
    }
  }, [_c('v-toolbar-title', {
    staticClass: "ml-2"
  }, [_vm._v("Update Invoice")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function ($event) {
        return _vm.closeEditItem();
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('v-card-text', [_c('v-container', [_vm._v(" " + _vm._s(_vm.tempFortnox) + " "), _vm.editItem.status !== 0 ? _c('v-text-field', {
    staticClass: "mt-5",
    attrs: {
      "label": "Invoice Number",
      "hide-details": "auto",
      "rules": _vm.validate.input,
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.editItem.fortnoxNumber,
      callback: function ($$v) {
        _vm.$set(_vm.editItem, "fortnoxNumber", $$v);
      },
      expression: "editItem.fortnoxNumber"
    }
  }) : _vm._e(), _c('v-textarea', {
    staticClass: "mt-4",
    attrs: {
      "outlined": "",
      "label": "Comment",
      "hide-details": "",
      "clearable": ""
    },
    model: {
      value: _vm.editItem.comment,
      callback: function ($$v) {
        _vm.$set(_vm.editItem, "comment", $$v);
      },
      expression: "editItem.comment"
    }
  })], 1)], 1), _c('v-card-actions', {
    staticClass: "pb-8 pr-8 pt-0"
  }, [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.closeEditItem();
      }
    }
  }, [_vm._v(" Cancel")]), _c('v-btn', {
    attrs: {
      "loading": _vm.loading,
      "color": "accent"
    },
    on: {
      "click": _vm.updateInvoice
    }
  }, [_vm._v(" Save ")])], 1)], 1)], 1)], 1) : _vm._e(), _vm.markAsItem ? _c('v-dialog', {
    attrs: {
      "max-width": "700px"
    },
    on: {
      "click:outside": function ($event) {
        return _vm.closeMarkAsItem();
      }
    },
    model: {
      value: _vm.markAsItem,
      callback: function ($$v) {
        _vm.markAsItem = $$v;
      },
      expression: "markAsItem"
    }
  }, [_c('v-form', {
    ref: "markAsItemForm"
  }, [_c('v-card', [_c('v-toolbar', {
    staticClass: "title",
    attrs: {
      "fixed": "",
      "color": "titlebg",
      "dark": ""
    }
  }, [_c('v-toolbar-title', [_vm._v("Mark as " + _vm._s(_vm.statusList.find(function (item) {
    return item.id === (_vm.markAsItem.status !== 3 ? _vm.markAsItem.status + 1 : _vm.markAsItem.status);
  }).label) + " ")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function ($event) {
        return _vm.closeMarkAsItem();
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('v-card-text', [_c('v-container', [_vm.markAsItem.status === 0 ? _c('v-text-field', {
    staticClass: "mt-5",
    attrs: {
      "label": "Invoice Number",
      "hide-details": "auto",
      "rules": _vm.validate.input,
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.markAsItem.fortnoxNumber,
      callback: function ($$v) {
        _vm.$set(_vm.markAsItem, "fortnoxNumber", $$v);
      },
      expression: "markAsItem.fortnoxNumber"
    }
  }) : _vm._e(), _c('v-textarea', {
    staticClass: "mt-4",
    attrs: {
      "outlined": "",
      "label": "Comment",
      "hide-details": "",
      "clearable": ""
    },
    model: {
      value: _vm.markAsItem.comment,
      callback: function ($$v) {
        _vm.$set(_vm.markAsItem, "comment", $$v);
      },
      expression: "markAsItem.comment"
    }
  })], 1)], 1), _c('v-card-actions', {
    staticClass: "pb-8 pr-8 pt-0"
  }, [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.closeMarkAsItem();
      }
    }
  }, [_vm._v(" Cancel")]), _c('v-btn', {
    attrs: {
      "loading": _vm.loading,
      "color": "accent"
    },
    on: {
      "click": function ($event) {
        return _vm.markAs(_vm.markAsItem.status + 1);
      }
    }
  }, [_vm._v(" Mark as " + _vm._s(_vm.statusList.find(function (item) {
    return item.id === _vm.markAsItem.status + 1;
  }).label) + " ")])], 1)], 1)], 1)], 1) : _vm._e(), _vm.markSelected ? _c('v-dialog', {
    attrs: {
      "max-width": "700px"
    },
    model: {
      value: _vm.markSelected,
      callback: function ($$v) {
        _vm.markSelected = $$v;
      },
      expression: "markSelected"
    }
  }, [_c('v-form', {
    ref: "markAsItemSelectedForm"
  }, [_c('v-card', [_c('v-toolbar', {
    staticClass: "title",
    attrs: {
      "fixed": "",
      "color": "titlebg",
      "dark": ""
    }
  }, [_vm._v("Mark as " + _vm._s(_vm.statusList.find(function (item) {
    return item.id === (_vm.status !== 3 ? _vm.status + 1 : _vm.status);
  }).label) + " ")]), _c('v-card-text', [_c('v-container', [_vm.status === 0 ? _c('v-text-field', {
    staticClass: "mt-5",
    attrs: {
      "label": "Invoice Number",
      "hide-details": "auto",
      "rules": _vm.validate.input,
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.selectedItems.fortnoxNumber,
      callback: function ($$v) {
        _vm.$set(_vm.selectedItems, "fortnoxNumber", $$v);
      },
      expression: "selectedItems.fortnoxNumber"
    }
  }) : _vm._e(), _c('v-textarea', {
    staticClass: "mt-4",
    attrs: {
      "outlined": "",
      "label": "Comment",
      "hide-details": "",
      "clearable": ""
    },
    model: {
      value: _vm.selectedItems.comment,
      callback: function ($$v) {
        _vm.$set(_vm.selectedItems, "comment", $$v);
      },
      expression: "selectedItems.comment"
    }
  })], 1)], 1), _c('v-card-actions', {
    staticClass: "pb-8 pr-8 pt-0"
  }, [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.markSelected = null;
      }
    }
  }, [_vm._v(" Cancel")]), _c('v-btn', {
    attrs: {
      "loading": _vm.loading,
      "color": "accent"
    },
    on: {
      "click": function ($event) {
        _vm.markAsSelectedItems(_vm.status);
        _vm.markSelected = false;
      }
    }
  }, [_vm._v(" Mark as " + _vm._s(_vm.statusList.find(function (item) {
    return item.id === _vm.status + 1;
  }).label) + " ")])], 1)], 1)], 1)], 1) : _vm._e(), _vm.eventDetail ? _c('v-dialog', {
    attrs: {
      "max-width": "700px"
    },
    on: {
      "click:outside": function ($event) {
        _vm.eventDetail = false;
      }
    },
    model: {
      value: _vm.eventDetail,
      callback: function ($$v) {
        _vm.eventDetail = $$v;
      },
      expression: "eventDetail"
    }
  }, [_c('v-card', [_c('v-toolbar', {
    staticClass: "title",
    attrs: {
      "fixed": "",
      "color": "titlebg",
      "dense": "",
      "dark": ""
    }
  }, [_c('v-toolbar-title', [_vm._v("Invoice Events History")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function ($event) {
        _vm.eventDetail = false;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('div', {
    staticClass: "px-4 py-4"
  }, [_c('v-row', [_c('v-col', [_c('v-card-text', {
    staticClass: "py-0 elevation-2 rounded-lg",
    staticStyle: {
      "max-height": "500px",
      "overflow": "auto"
    }
  }, [_c('v-timeline', {
    attrs: {
      "align-top": "",
      "dense": ""
    }
  }, _vm._l(_vm.eventDetail.events, function (event, index) {
    var _event$user$fullName;
    return _c('v-timeline-item', {
      key: index,
      attrs: {
        "small": ""
      }
    }, [_c('v-row', {
      staticClass: "pt-1"
    }, [_c('v-col', {
      attrs: {
        "cols": "3"
      }
    }, [_c('strong', [_vm._v(_vm._s(event.datetime))])]), _c('v-col', [_c('strong', [_vm._v(_vm._s((_event$user$fullName = event.user.fullName) !== null && _event$user$fullName !== void 0 ? _event$user$fullName : event.user.name))]), _c('div', {
      staticClass: "text-caption"
    }, [_vm._v(" " + _vm._s(event.description) + " ")])])], 1)], 1);
  }), 1)], 1)], 1)], 1)], 1)], 1)], 1) : _vm._e(), _c('v-dialog', {
    staticClass: "pa-5",
    attrs: {
      "loading": _vm.isLoading,
      "width": "1000px"
    },
    on: {
      "click:outside": function ($event) {
        _vm.detailDialog = false;
      }
    },
    model: {
      value: _vm.detailDialog,
      callback: function ($$v) {
        _vm.detailDialog = $$v;
      },
      expression: "detailDialog"
    }
  }, [_c('v-card', [_c('v-toolbar', {
    staticClass: "title",
    attrs: {
      "fixed": "",
      "color": "titlebg",
      "dense": "",
      "dark": ""
    }
  }, [_c('v-toolbar-title', {
    staticClass: "ml-2"
  }, [_vm._v("Company : " + _vm._s(_vm.companyDetail && _vm.companyDetail.name) + " ")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function ($event) {
        _vm.detailDialog = false;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _vm.companyDetail ? _c('companyDetail', {
    attrs: {
      "company": _vm.companyDetail,
      "loading": _vm.isLoading
    }
  }) : _vm._e()], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }