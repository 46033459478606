<template>
  <div>
    <div v-for="(item, i) in items" :key="i">
      <div>
        <h4 class="grey--text text--darken-4 minw100">
          <v-tooltip top :open-delay="10" :close-delay="5">
            <template v-slot:activator="{on, attrs}">
              <v-icon
                color="mango-red"
                @click="copyToClipboard(item)"
                small
                v-on="on"
                v-bind="attrs"
              >
                mdi-content-copy
              </v-icon>
            </template>
            <span>Copy</span>
          </v-tooltip>
          <span class="ml-1 font-weight-bold">{{ item }}</span>
        </h4>
      </div>
      <v-divider v-if="i + 1 !== items.length" :key="i"></v-divider>
    </div>
  </div>
</template>

<script>
import {copyToClipboard} from "@/util/helpers";
import common from "@/mixins/common";

export default {
  name: "BookingIdField",
  methods: {copyToClipboard},
  props: ['items'],
  mixins: [common]
}
</script>

<style scoped>

</style>
